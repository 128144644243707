import { Container, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

import lottieError from "@/assets/lottie/lottie-error.json";
import LottieLoader from "@/components/LottieLoader";

export default function ErrorView() {
  const error = useRouteError();

  return (
    <Container
      id="error-page"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <LottieLoader
        animationData={lottieError}
        loop={false}
        style={{ width: "50%" }}
      />
      <Typography variant="body2" color="text.secondary">
        {String(error)}
      </Typography>
    </Container>
  );
}

import { post } from "./axios";

import { Request } from "@/types/request";

export const getAllRequest = async () => {};

export const createRequest = async (req: Request) => {
  try {
    const res = await post("/request", req);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
